import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import Code from '@local/components/typography/Code';
import { MainCourseTitle } from '@local/components/typography/titles';
import BasicInfo from '@local/components/course/BasicInfo';
import CourseForm from '@local/components/course/CourseForm';
import CourseContent from '@local/components/course/CourseContent';
import Requirements from '@local/components/course/Requirements';
import Place from '@local/components/course/Place';
import PriceList from '@local/components/course/PriceList';
import PriceCard from '@local/components/course/PriceCard';
import TableOfContents from '@local/components/course/TableOfContents';

const Ponuka = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>React kurz - {data.site.siteMetadata.title}</title>
            </Helmet>
            <Layout>
                <MainCourseTitle>React kurz</MainCourseTitle>

                <Typography>
                    Tri a pol až štvordňový workshop, na ktorom sa naučíte pracovať v React-e,
                    manažovať stav aplikácie cez kontext alebo Redux, routovanie riešiť cez React
                    Router a ako bonus si ukážeme aj základy práce v MUI.
                </Typography>

                <TableOfContents />

                <BasicInfo>
                    <Typography>
                        Môj React kurz je koncipovaný pre programátorov, ktorí už majú znalosti
                        programovania v JavaScripte a chceli by sa naučiť React. Naučím vás všetko
                        podstatné k tomu, aby ste hneď po skončení kurzu vedeli efektívne vytvárať
                        Single Page aplikácie v React-e s routovaním na strane frontendu a s
                        manažmentom aj rozsiahleho stavu.
                    </Typography>
                    <Typography>
                        Snažím sa držať krok s dobou, takže budeme písať funkcionálne komponenty
                        namiesto triednych, ako bundler použijeme vite namiesto webpack-u, budeme
                        používať veľa hookov, React Router bude vo verzii 6 a na udržiavanie stavu
                        aplikácie použijeme Redux Toolkit.
                    </Typography>
                    <Typography>
                        Ako bonus si ukážeme komponentovú knižnicu MUI, ktorá stojí na design
                        systéme Material Design, ale je mimoriadne flexibilná a prispôsobiteľná a
                        viete pomocou nej implementovať aj vlastný design systém.
                    </Typography>
                </BasicInfo>

                <CourseForm>
                    <Typography>
                        Školenie robím vo forme workshopu, čo znamená, že od uchádzačov vyžadujem,
                        aby mali k dispozícii vlastný notebook a počas školenia budú programovať.
                        Zvyčajne najskôr vysvetlím nejakú teóriu, ukážem príklad, ktorý si odpíšu a
                        potom zadávam úlohu, ktorú musia vypracovať. Počas ich práce chodím pomedzi
                        nich a s každým konzultujem postup a navrhnuté riešenie.
                    </Typography>

                    <Typography>
                        Úlohy zadávam tak, aby ich vypracovanie nezaberalo dlhý čas. Zároveň sa
                        snažím pôsobiť prívetivo a skôr ako partner pri programovaní, než učiteľ,
                        aby som nevytváral účastníkom stres. Koniec koncov je cieľom sa niečo naučiť
                        a nie je to súťaž o najrýchlejšie alebo najlepšie riešenie.
                    </Typography>
                </CourseForm>

                <CourseContent>
                    <ul>
                        <li>
                            <strong>Deň 1</strong>
                            <ul>
                                <li>Veľmi stručné vysvetlenie princípov, na ktorých React stojí</li>
                                <li>
                                    Vytvorenie projektu (pomocou <Code>vite</Code>)
                                </li>
                                <li>
                                    Čo je to komponenta, čo je to a ako funguje JSX a tvorba prvých
                                    komponent
                                </li>
                                <li>Spracovanie eventov v React-e</li>
                                <li>Hooks</li>
                                <li>
                                    <Code>useState</Code> podrobne
                                </li>
                                <li>
                                    <Code>useEffect</Code> podrobne
                                </li>
                                <li>Stručne o ostatných hookoch</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Deň 2</strong>
                            <ul>
                                <li>Správa stavu aplikácie pomocou kontextu</li>
                                <li>
                                    Vlastný <Code>Context Provider</Code>
                                </li>
                                <li>
                                    Používanie <Code>useContext</Code> hooku
                                </li>
                                <li>
                                    Väčšia úloha: tvorba vlastného routera na precvičenie si
                                    všetkých doteraz naučených znalostí + tvorba vlastných hookov
                                </li>
                                <li>
                                    Predstavenie React Router (v6) a jeho použitie namiesto
                                    vlastného riešenia
                                </li>
                                <li>Hooky pre React Router</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Deň 3</strong>
                            <ul>
                                <li>Čo je to stav aplikácie</li>
                                <li>Predstavenie patternu Flux pre správu stavu</li>
                                <li>Redux a jeho filozofia</li>
                                <li>Názvoslovie: store, state, akcia, reducer, selector</li>
                                <li>
                                    <Code>useSelector</Code> hook
                                </li>
                                <li>
                                    Preskúšanie si práce v čistom Redux-e (immutable state, reducer,
                                    akcie, action creator, re-ducks)
                                </li>
                                <li>Redux Toolkit (RTK)</li>
                                <li>
                                    Čo je to <Code>slice</Code> a ako vyzerá
                                </li>
                                <li>
                                    Ako funguje immutable state v RTK (<Code>immer</Code>)
                                </li>
                                <li>
                                    Prepis aplikácie z predchádzajúceho dňa zo stavu držanom v
                                    kontexte do Redux-u pomocou RTK
                                </li>
                                <li>Ukážka Redux Dev Tools</li>
                                <li>
                                    Čo to je <Code>thunk</Code>?
                                </li>
                                <li>
                                    Ako riešiť podobajúce sa asynchrónne operácie (
                                    <Code>createAsyncThunk</Code>)?
                                </li>
                                <li>
                                    Ako generovať hodnoty (id, náhodné hodnoty...) mimo reducera (
                                    <Code>prepare</Code>)
                                </li>
                                <li>Veľmi krátko o RTK Query a React Query</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Deň 4</strong>
                            <ul>
                                <li>
                                    Predstavenie komponentovej knižnice MUI, čo to je, aké
                                    komponenty obsahuje, kde hľadať dokumentáciu
                                </li>
                                <li>
                                    Použitie <Code>fontsource</Code> na inštaláciu fontov
                                </li>
                                <li>
                                    Štýlovanie pomocou <Code>styled</Code>
                                </li>
                                <li>Stručne o tom, ako funguje spacing</li>
                                <li>Prispôsobovanie si témy</li>
                                <li>Prepojenie MUI a React Router</li>
                                <li>Vyskúšanie použitia AppBar komponentu</li>
                            </ul>
                        </li>
                    </ul>
                </CourseContent>

                <Requirements>
                    <ul>
                        <li>Znalosť programovania v JavaScript-e (nový JS od verzie ES2015)</li>
                        <li>Vlastný notebook alebo počítač</li>
                        <li>Nainštalovaný Node (lokálne alebo v docker kontajneri)</li>
                    </ul>
                </Requirements>

                <Place>
                    <Typography>Vo vašej firme alebo online.</Typography>
                </Place>

                <PriceList>
                    <PriceCard where="U vás" howMany="Najviac 5 účastníkov" price="2 500,- €" />
                    <PriceCard where="U vás" howMany="Najviac 10 účastníkov" price="2 800,- €" />
                    <PriceCard where="Online" howMany="Najviac 5 účastníkov" price="2 200,- €" />
                </PriceList>
            </Layout>
        </>
    );
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default Ponuka;
