import { styled } from '@mui/material';

const Code = styled('code')(({ theme }) => ({
    fontFamily: 'monospace',
    fontSize: '0.8125rem',
    display: 'inline-block',
    color: '#cc0000',
    backgroundColor: '#dedede',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: '4px',
}));

export default Code;
